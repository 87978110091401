<template>
  <div>
    <a-card
      :style="{ marginBottom: '1em' }"
      :header="$t('common.search')"
      :editable="false"
    >
      <template
        #header-buttons
      >
        <div class="is-pulled-right is-flex buttons">
          <b-button
            icon-left="file-excel-box"
            @click="exportCsv"
          >
            {{ $t('common.navigation.export_to_csv') }}
          </b-button>
          <b-button
            icon-left="filter-remove"
            @click="() => $store.dispatch('failureNodes/resetFilters')"
          >
            {{ $t('common.navigation.reset_filters') }}
          </b-button>
          <a-select
            :manual-doc="userDoc"
            field="routes.failureNodes.orderBy"
            :label="$t('common.order')"
            :options="keys($store.getters['failureNodes/orderOptions'])"
            :labels="$store.getters['failureNodes/orderOptions']"
          />
        </div>
      </template>

      <filters />
      <a-input
        :style="{ marginTop: '1em' }"
        :manual-doc="userDoc"
        field="routes.failureNodes.search"
        :label="$t('common.quick_search')"
      />
    </a-card>
    <a-table
      :columns="user.routes.failureNodes.columns"
      :data="$store.getters['failureNodes/displayedItems']"
    />
    <more-docs-button
      module="failureNodes"
    />
  </div>
</template>

<script>
import ATable from '@/components/ATable'
import MoreDocsButton from '@/components/MoreDocsButton'
import { mapGetters, mapActions } from 'vuex'
import { keys } from 'lodash'
import Filters from './FailureNodes/Filters'

export default {
  components: {
    Filters,
    MoreDocsButton
  },
  data() {
    return {
      userDoc: { module: 'user' }
    }
  },
  computed: {
    where () {
      return this.user.routes.failureNodes.where
    }
  },
  created () {
    this.$store.dispatch('failureNodes/fetchDocs')
  },
  methods: {
    keys,
    exportCsv() {
      const filename = `Failure Nodes ${this.where.failure && this.where.failure.operator ? this.where.failure.operator.name : ''} - ${this.where.part && this.where.part.supplier ? this.where.part.supplier.name : ''}`
      this.$store.dispatch('failureNodes/exportToCsv', { filename })
    }
  }
}
</script>
